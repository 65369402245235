/* Logo Styling */
.crypto-logo {
  display: block;
  cursor: pointer;
  display: flex;
  justify-content: center;
  max-width: 100%; /* Ensure the logo fits within the sidebar */
  height: auto;
  max-height: 120px; /* Limit the height to ensure it's not too large */
  margin: 0 auto 20px auto; /* Center the logo horizontally and add space below */
}

.crypto-logo-image {
  width: 50px; /* Set an appropriate width */
  height: 50px; /* Set an appropriate height */
}

/* Center the content inside the sidebar */
.crypto-sidebar {
  text-align: center; /* Align text and logo to the center */
}

/* Tab buttons in the Crypto Sidebar */
.crypto-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.crypto-tabs button {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  background-color: #252524;
  color: white;
  border: none;
  text-align: center;
}

.crypto-tabs .active {
  background-color: #555;
}

/* List of coins in the Crypto Sidebar */
.crypto-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.crypto-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #9a9a9a;
  background-color: #ffffffd2;

}


.crypto-symbol {
  font-weight: bold;
  font-size: 1.1em;
  /* Add some space between the symbol and the arrow */
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  color: rgb(0, 0, 0);
}


.crypto-symbol::after {
  margin-left: 5px; /* Optional: space between symbol and arrow */
}


.crypto-price,
.crypto-change {
  font-size: 14px;
  color: rgb(0, 0, 0);
}

.crypto-change.positive {
  color: rgb(0, 72, 0);
}

.crypto-change.negative {
  color: rgb(180, 0, 0);
}
