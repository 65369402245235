/* Desktop Navbar */
.navbar {
  background-color: #252524;
  padding: 5px 10px; /* Adjust the padding as needed */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Keep brand and menu spaced */
  width: 100%; /* Ensure it takes up the full width of its container */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 60px;
  direction: rtl;
  box-sizing: border-box;
}

/* Fix the navbar brand to ensure it doesn't overflow */
.navbar-brand {
  color: white;
  font-size: 20px;
  margin-left: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px; /* Add padding to prevent trimming */
}

/* Navbar ul styling */
.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  flex-grow: 1;
  gap: 20px;
} 

.navbar li {
  padding: 10px; /* Adjust padding to reduce white space */
  color: white;
  cursor: pointer;
  text-align: right;
}

.navbar li:hover {
  background-color: #555;
}

/* Add Search Field Styling */

/* Style for the search input on desktop */
.navbar-search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 300px;
  margin-right: 20px; /* Adjust the spacing */
}

.navbar-search input[type="text"] {
  padding: 8px 12px;
  border-radius: 20px;
  border: 1px solid #ccc;
  outline: none;
  transition: all 0.3s ease-in-out;
  width: 100%;
  background-color: #fff;
  color: #333;
}

.navbar-search input[type="text"]::placeholder {
  color: #999;
}

.navbar-search input[type="text"]:focus {
  border-color: #888;
  background-color: #f9f9f9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Social Icon Styling */
.social-icons, .social-icons-mobile {
  display: flex;
  gap: 80px; /* Increase the gap between icons */
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  fill: #1da1f2; /* Twitter/Telegram default color */
  transition: transform 0.3s, fill 0.3s;
}

.icon:hover {
  transform: scale(1.1);
  fill: #0077b5; /* Color on hover */
}

/* Positioning for Desktop */
.navbar .social-icons {
  margin-left: 20px;
}

/* Mobile-specific search styling */
.mobile-navbar-search {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
}

.mobile-navbar-search input[type="text"] {
  padding: 8px;
  border-radius: 20px;
  border: 1px solid #ccc;
  outline: none;
  width: 90%;
  background-color: #fff;
  color: #333;
}

.mobile-navbar-search input[type="text"]::placeholder {
  color: #999;
}

.mobile-navbar-search input[type="text"]:focus {
  border-color: #888;
  background-color: #f9f9f9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Mobile-specific styles */
.mobile-navbar {
  background-color: #333;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure it's above other elements */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburger-menu {
  display: flex;
  justify-content: space-between; /* Space between hamburger and brand */
  align-items: center;
  width: 100%; /* Full width */
  direction: rtl; /* Right-to-left layout */
}

.hamburger-icon {
  font-size: 30px;
  color: white;
  margin-right: 15px; /* Icon on the right */
  cursor: pointer;
}

.mobile-navbar-brand {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: white;
  margin-left: 15px; /* Brand on the right */
}

.brand-image {
  width: 30px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin: 10px; /* Add space between image and text */
}

/* Ensure space between the mobile navbar and accordion */
.accordion {
  margin-top: 62px; /* 60px height of the navbar + 2px space */
}

/* Mobile Menu */
.mobile-menu {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #333;
  width: 100%;
  padding: 10px 0;
  z-index: 1001;
}

.mobile-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-menu li {
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  text-align: right;
}

.mobile-menu li:hover {
  background-color: #555;
}

/* Modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the modal is above all other elements */
}

/* Ensure the modal content is also on top of everything */
.modal-content {
  background-color: var(--bg-color);
  color: var(--text-color);
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  z-index: 10000; /* Keep it above the modal overlay */
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.modal-content button:hover {
  background-color: #555;
}

/* Social icons beside the brand in mobile */
.social-icons-mobile {
  display: flex;
  gap: 30px; /* Space between icons */
  margin-left: 10px; /* Add space from the brand */
  align-items: center; /* Center vertically with the brand */
}