.tag-filter-bar {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: var(--tag-filter-bar-color);
    padding: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  
  .tag-filter-bar-tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .tag-chip {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    color: black; /* Set text color for tags */

  }
  
  .remove-tag-button {
    background: none;
    border: none;
    color: red;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
  }
  
  .clear-all-button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  