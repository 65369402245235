  
  /* The loading overlay that appears while data is loading */
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* White background with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Ensure it's above other content */
  }


  /* Loading icon (logo) */
  .loading-icon {
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite; /* Add a spin animation */
  }
  
  /* Animation for spinning the logo */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  