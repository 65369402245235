/* Grid layout for the App */
.app-grid {
  display: grid;
  grid-template-columns: 230px 1fr; /* Fixed width for left sidebar, remaining space for main content */
  grid-template-rows: 1fr auto; /* Main content takes remaining space, footer at the bottom */
  grid-template-areas:
    "sidebar-left main-content"
    "footer footer";
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent scrolling on the grid itself */
}

/* Left Sidebar with Crypto Price Gadget */
.app-sidebar-left {
  grid-area: sidebar-left;
  /* background-color: #252524; Black background */
  background-color: var(--crypto-sidebar-bg-color);
  color: var(--text-color);
  padding: 20px; /* Padding for content */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 230px; /* Fixed width */
  height: 100%; /* Full height within the grid */
  position: relative; /* Make it relative for sticky content inside */
  box-sizing: border-box; /* Ensure padding is included in the element’s width */
  color: #fff; /* White text for better contrast */
  border: 1px solid var(--crypto-sidebar-bg-color); /* Black border to match the background */
}

/* Sticky positioning for content inside the sidebar (CryptoPrice) */
.crypto-sidebar {
  position: sticky;
  top: 20px; /* Adjust based on your needs */
  width: 100%; /* Full width of the sidebar */
  padding: 1px; /* Padding for inner content */
  overflow-y: auto; /* Ensure the sidebar content scrolls if too long */
  box-sizing: border-box;
  background-color: var(--crypto-sidebar-bg-color);
  color: var(--text-color);
}

/* Main content area with scrollable NewsFeed and sticky ArticleDetails */
.app-main-content {
  grid-area: main-content;
  padding: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; /* Ensure the content is scrollable and not hidden under the footer */
  position: relative;
}

/* Wrapper for the entire content (Navbar, NewsFeed, ArticleDetails) */
.content-wrapper {
  display: flex;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent horizontal scrolling */
}

/* News feed wrapper - takes 58% of the width */
.news-feed-wrapper {
  width: 58%;
  overflow-y: auto; /* Independent vertical scrolling */
  height: 100%; /* Ensure it takes full height within the content-wrapper */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  position: relative; /* This is needed to allow the spinner to position itself properly */
  background-color: var(--bg-color);
  color: var(--text-color);
}

/* Ensure the Navbar stays aligned within the news-feed-wrapper */
.news-feed-wrapper .navbar-wrapper {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #333;
  width: 100%; /* Keep the navbar width to 100% of the news-feed-wrapper */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Ensure NewsFeed fills the remaining space after the navbar */
.news-feed-wrapper .news-feed {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  margin-top: 0; /* Remove any top margin */
  padding-top: 5px; /* Remove any top padding */
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Article details wrapper - takes 40% of the width */
.article-details-wrapper {
  width: 42%; /* Article details take 40% of the width */
  overflow-y: auto; /* Independent scrolling for article details */
  height: 100%; /* Full height for article details */
  display: flex;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
  background-color: var(--bg-color);
  color: var(--text-color);
}

.article-details-wrapper > * {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.widget-header {
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 2px; /* Adjust this value to decrease the space */
  padding: 20px;
  color: var(--text-color);
  background-color: var(--bg-color);
  box-sizing: border-box;
}

.widget-header strong {
  font-size: 1.4rem;
  display: block;
  margin-bottom: 10px; /* Space between title and description */
}

/* Footer area */
.app-footer {
  grid-area: footer;
  background-color: var(--bg-color);
  color: var(--text-color);  
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  z-index: 1; /* Ensure footer stays behind other elements */
  position: relative; /* Ensure it's relative and not floating over content */
  height: auto; /* Allow auto height if content needs more space */
}

/* Loading spinner styles for desktop */
.loading-spinner-desktop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  z-index: 999; /* Ensure it's on top of the content */
}

.loading-logo {
  max-width: 100px;
  position: absolute;
  z-index: 1001;
  animation: none; /* Disable animation for the logo */
}

.circle-spinner {
  position: relative;
  width: 120px;
  height: 120px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive layout for mobile screens */
@media (max-width: 768px) {
  .app-grid {
    grid-template-columns: 1fr; /* Remove the sidebar and make main content full width */
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      "main-content"
      "footer";
  }

  .app-sidebar-left, .crypto-sidebar {
    display: none; /* Hide sidebar and crypto gadget on mobile */
  }

  .app-main-content {
    width: 100%;
    overflow-y: visible;
  }

  .content-wrapper {
    flex-direction: column; /* Stack content vertically for mobile */
  }

  .navbar-wrapper, .news-feed-wrapper, .article-details-wrapper {
    width: 100%;
    height: auto;
    overflow-x: hidden;
  }

  /* Loading spinner styles for mobile */
  .loading-spinner {
    position: fixed; /* Full screen for mobile */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    z-index: 9999; /* Higher z-index for mobile to cover everything */
  }
}

.coin-tag {
  margin-right: 2px; /* Add space between the tags */
  font-weight: bold; /* Make the tags bold */
  color: #007bff; /* Set the hyperlink color */
  text-decoration: none; /* Remove underline */
  cursor: pointer;
}

.coin-tag:hover {
  text-decoration: underline; /* Add underline on hover to simulate a hyperlink */
}
