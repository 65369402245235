/* Default theme (light) */
:root {
  --bg-color: #ffffff;
  --text-color: #000000;
  --navbar-bg-color: #f8f9fa;
  --crypto-sidebar-bg-color: #333333;
  --border-color:  #f1f1f1;
  --accent-color: #666;
  --link-color: #007bff;
  --tag-filter-bar-color: #ffffff;
}

/* Dark mode override */
.dark-mode {
  /* --bg-color: #1a191f; */
  --bg-color: #282828;
  --text-color: #eeedf2;
  --navbar-bg-color: #333333;
  --crypto-sidebar-bg-color: #212020;
  --border-color: #373b3e;
  --accent-color: #fdd434;
  --link-color: #fdd434;
  --tag-filter-bar-color: #373b3e;

}


/* Global Styles with RTL support */
body {
  margin: 0;
  font-family: 'Tajawal', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  direction: rtl;
  background-color: var(--bg-color);
  color: var(--text-color);

}
.navbar {
  background-color: var(--navbar-bg-color);
}
/* Global text and layout styles */
h1, h2, h3, p {
  margin: 0;
  padding: 4px 14px;
}

/* Global image styles */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Flex container for independent scrolling */
.container {
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  width: 100%;
  height: auto;
}

/* Right side: News feed */
.news-feed {
  width: 100%;
  padding-right: 6px;
  overflow-y: auto;
  padding: 5px;
  background-color: var(--border-color);
  height: 100%;
  margin-top: 30px;
}

.news-item {
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
}

.news-item.selected {
  border-color: #007bff;
}

.news-item h2 {
  margin: 0 0 5px 0;
  font-size: 18px;
}

.news-item p {
  margin: 0;
  color: #555;
  font-size: 14px;
}

/* Article card in the feed */
.article-card {
  background-color: var(--bg-color);
  color: var(--text-color);
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}

/* Thumbnail Container */
.article-thumbnail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10%;
}

.article-card:hover {
  background-color: var(--border-color);
}

.article-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 5px;
  word-wrap: break-word;
}

/* Description styling (no need for line-clamping) */
.article-description {
  color: var(--text-color);
  margin-bottom: 1px;
  word-wrap: break-word;
  font-size: 17px;
}

.article-date {
  font-size: 0.9em;
  color: #888;
  margin-bottom: 5px;
}

.article-thumbnail {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
  border-radius: 10px;
}

/* Date and Source Styling */
.article-info {
  text-align: center;
}

.article-date,
.article-source {
  display: block;
  font-size: 0.8em;
  color: var(--accent-color);
  margin-bottom: 3px;
}

/* Left side content */
.article-content {
  flex: 1;
  padding-left: 15px;
}

.article-link:hover {
  text-decoration: underline;
}

.article-card.selected {
  background-color: #e6e6e6;
  border: 2px solid #007bff;
}

.article-details {
  width: 100%;
  padding: 10px;
  background-color: var(--bg-color);
  color: var(--text-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.article-details h1 {
  margin-bottom: 10px;
  font-size: 24px;
}

.article-details p {
  margin-bottom: 10px;
  line-height: 1.5;
}

.article-details img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 10px 0;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

/* Optional: add hover and focus effects */
a:hover,
a:focus {
  text-decoration: underline;
}

/* Independent scrolling only on larger screens */
@media (min-width: 769px) {
  .container {
    height: 80vh;
  }

  .news-feed {
    width: 60%;
    overflow-y: auto;
    height: 100%;
  }

  .article-details {
    width: 40%;
    overflow-y: auto;
    height: 100%;
  }
}

/* Mobile screens */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }


  .news-feed, .article-details {
    width: 100%;
    height: auto;
    overflow-y: visible;
  }
}
